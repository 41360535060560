export const education = [
  {
    eduDate: "2014 - 2015",
    eduTitle: "San Francisco Bay University",
    eduIns: "Masters, Computer Science - USA",
    eduDesc: "",
  },
  {
    eduDate: "2009 - 2013",
    eduTitle: "Bachelor's Degree Computer Science",
    eduIns: "Jawaharlal Nehru Technological University, Hyderabad",
    eduDesc: "",
  },
  {
    eduDate: "2007 - 2009",
    eduTitle: "Higher Secondary Education",
    eduIns: "Sri Chaitanya Jr College, Hydernagar - India",
    eduDesc: "",
  },
  {
    eduDate: "2005 - 2007",
    eduTitle: "Secondary School Certificate",
    eduIns: "Krishnaveni Talent School - India",
    eduDesc: "",
  },
  {
    eduDate: "1996 - 2005",
    eduTitle: "School Certificate",
    eduIns: "Sanghamitra School - India",
    eduDesc: ".",
  },
];
