import React from "react";
import { Helmet } from "react-helmet";
import { BottomBar2 } from "../../components/BottomBar";
import { ContactInfoProfiles2 } from "../../components/ContactInfoProfiles";
import { ContactUs } from "../../components/ContactUs";
import { Header } from "../../components/Header";
import { PageTitle } from "../../components/PageTitle";

const ContactPage = () => {
  return (
    <React.Fragment>
      <Helmet>
        <title>Contact - Sri Bhargav Krishna Adusumilli Personal Portfolio or CV/Resume React App</title>
      </Helmet>
      <Header></Header>
      {/* <PageTitle
        title="Contact Me"
        hometitle="Home"
        homeURL="/"
        currentPage="Contact Me"
      ></PageTitle> */}
      <section className="pageWrap relative w-full">
        <iframe className="w-full relative border-0 -mt-[8.125rem] min-h-[35rem] sm:min-h-[45rem] md:min-h-[60.3125rem]"
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3584.576665114848!2d-80.14111552601737!3d26.047403896743322!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x88d9aaf76addbfb1%3A0xbffeabf75a90018a!2s301%20SE%203rd%20St%2C%20Dania%20Beach%2C%20FL%2033004%2C%20USA!5e0!3m2!1sen!2sin!4v1719341753985!5m2!1sen!2sin"
          allowfullscreen
          loading="lazy" title="Contact Map"
        ></iframe>
        {/* Page Wrap */}
      </section>
      <ContactUs></ContactUs>
      {/* <ContactInfoProfiles2></ContactInfoProfiles2> */}
      <BottomBar2></BottomBar2>
    </React.Fragment>
  );
};

export default ContactPage;
