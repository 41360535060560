import React, { Fragment } from "react";
import { Helmet } from "react-helmet";
import { Header } from "../../components/Header";

import { Link } from "react-router-dom";
import PatternImg2 from "../../assets/images/patternImg2.jpg";
import PatternImg from "../../assets/images/patternImg3.jpg";
import { awards } from "../../data/awards";
import { SectionTitle } from "../SectionTitles";

const AwardsPage = (props) => {
  return (
    <Fragment>
    <Helmet>
        <title>Home - Sri Bhargav Krishna Adusumilli Personal Portfolio or CV/Resume React App</title>
      </Helmet>
      <Header></Header>
    <section className="postsWrap py-[5rem] sm:py-[6rem] md:py-[7.5rem] relative w-full">
      <div
        className="fixedBg bg-left-top bg-no-repeat opacity-20"
        style={{ backgroundImage: `url(${PatternImg})` }}
      ></div>
      <div className="container sm:container md:container lg:container xl:max-w-[73.125rem] mx-auto">
        <SectionTitle
          title="Featured"
          titleInner="Awards"
          desc="Celebrating prestigious recognitions for excellence and innovation in technology."
        ></SectionTitle>
        <div className="flex flex-wrap gap-[1.875rem] relative w-full">
          {awards.map(
            (item, index) =>
              index < 2 && (
                <div className="postBox group relative flex flex-row w-full mb-10" key={index}>
      <div className="postImg overflow-hidden rounded-[10px] sm:rounded-[1.25rem] md:rounded-[1.875rem] w-1/3">
        <a href={`${item.postLink}`} title={item.postTitle}>
          <img
            className="w-full"
            src={item.postImg}
            alt={item.postTitle}
            style={{height:'200px', width:'100%'}}
          />
        </a>
      </div>
      <div className="postInfo relative z-[1] pl-4 pr-5 py-5 ml-4 bg-white flex-1">
        <div
          className="before:absolute before:inset-0 before:bg-gray before:rounded-[10px] before:opacity-70 before:z-[1] bg-blend-multiply absolute rounded-[10px] inset-0 w-full bg-no-repeat bg-center bg-cover z-[-1] bg-gray"
          style={{ backgroundImage: `url(${PatternImg2})` }}
        ></div>
        
        <h3 className="text-accent2 text-[1.25rem] font-Poppins font-semibold mt-2">
          <a
            className="hover:text-accent"
            href={`${item.postLink}`}
            title={item.postTitle}
          >
            {item.postTitle}
          </a>
          
        <span className="flex items-center text-accent gap-1 text-[1rem] font-NunitoSans font-bold">
        {item.postMonth} {item.postDate}
        </span>
        
        </h3>
        <i className="bg-[#d5daf2] h-[1px] border-0 relative before:absolute before:bg-accent before:w-[3.125rem] before:h-[3px] before:z-[1] block before:mt-[-1px] mt-5 mb-4"></i>
        <span className="flex items-center text-accent gap-1 text-[1rem] font-NunitoSans font-bold">
          By
          <Link
            className="hover:text-accent2"
            to={item.postAuthorLink}
            title={item.postAuthor}
          >
            {item.postAuthor}
          </Link>
        </span>
      </div>
    </div>
              )
          )}
          {/* Posts List */}
        </div>
        {/* <div className="viewAll text-center w-full mt-[3.125rem] md:mt-[4.0625rem]">
          <p className="text-desc2 font-NunitoSans text-[1rem] md:text-[1.125rem]">
            Want to see my professional work.{" "}
            <Link
              className="text-accent font-Poppins font-medium hover:text-accent2 underline underline-offset-8"
              to="/"
              title="Click here to View More"
            >
              Click here to View More
            </Link>
          </p>
        </div> */}
      </div>
    </section>
    </Fragment>
  );
};

export default AwardsPage;