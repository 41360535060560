import PostImg1 from "../assets/images/resources/pub1.png";
import PostImg2 from "../assets/images/resources/pub2.jpg";
import PostImg3 from "../assets/images/resources/postImg2-3.jpg";
import PostImg4 from "../assets/images/resources/postImg2-4.jpg";
import PostImg5 from "../assets/images/resources/postImg2-5.jpg";
import PostImg6 from "../assets/images/resources/postImg2-6.jpg";

export const publications = [
  {
    id: 1,
    postImg: PostImg1,
    postDate: " 14, 2024",
    postMonth: "Jun",
    postDateLink: "#",
    postTitle: "AI-INFUSED BLOCKCHAIN INNOVATIONS IN MANUFACTURING SUPPLY CHAINS FOR ECO-FRIENDLY PRACTICES TOWARDS A SUSTAINABLE FUTURE",
    postLink: "https://businessmanagementeconomics.org/article/view-2024/1356.html",
    postAuthor: "Vilnius Gediminas Technical University",
    postAuthorLink: "#",
    cateList: [],
  },
  {
    id: 2,
    postImg: PostImg2,
    postDate: "10 2024",
    postMonth: "May",
    postDateLink: "#",
    postTitle: "SCALABLE SOFTWARE ARCHITECTURE FOR DYNAMIC THREAT DETECTION AND MITIGATION IN IOT",
    postLink: "https://machineintelligenceresearchs.com/article/view/Abstract-2024-468.php",
    postAuthor: "Machine Intelligence Researchs",
    postAuthorLink: "#",
    cateList: [
      {
        cateItem: "Design,",
        cateLink: "#",
      },
      {
        cateItem: "Events,",
        cateLink: "#",
      },
      {
        cateItem: "Technology",
        cateLink: "#",
      },
    ],
  },
  {
    id: 3,
    postImg: PostImg3,
    postDate: "21",
    postMonth: "January",
    postDateLink: "#",
    postTitle: "Project that began allow second phase in worth",
    postLink: "#",
    postAuthor: "Berline Tokyo",
    postAuthorLink: "#",
    cateList: [
      {
        cateItem: "Design,",
        cateLink: "#",
      },
      {
        cateItem: "Technology,",
        cateLink: "#",
      },
      {
        cateItem: "Events",
        cateLink: "#",
      },
    ],
  },
  {
    id: 4,
    postImg: PostImg4,
    postDate: "15",
    postMonth: "June",
    postDateLink: "#",
    postTitle: "Multiethnic colleagues sitting at desk looking",
    postLink: "#",
    postAuthor: "John Doe",
    postAuthorLink: "#",
    cateList: [
      {
        cateItem: "Design,",
        cateLink: "#",
      },
      {
        cateItem: "Events,",
        cateLink: "#",
      },
      {
        cateItem: "Technology",
        cateLink: "#",
      },
    ],
  },
  {
    id: 5,
    postImg: PostImg5,
    postDate: "21",
    postMonth: "January",
    postDateLink: "#",
    postTitle: "Shot of two work colleage using a digital tablet",
    postLink: "#",
    postAuthor: "Berline Tokyo",
    postAuthorLink: "#",
    cateList: [
      {
        cateItem: "Design,",
        cateLink: "#",
      },
      {
        cateItem: "Technology,",
        cateLink: "#",
      },
      {
        cateItem: "Events",
        cateLink: "#",
      },
    ],
  },
  {
    id: 6,
    postImg: PostImg6,
    postDate: "15",
    postMonth: "June",
    postDateLink: "#",
    postTitle: "Beautiful Specialist with Short Pink Hair Talks",
    postLink: "#",
    postAuthor: "John Doe",
    postAuthorLink: "#",
    cateList: [
      {
        cateItem: "Design,",
        cateLink: "#",
      },
      {
        cateItem: "Events,",
        cateLink: "#",
      },
      {
        cateItem: "Technology",
        cateLink: "#",
      },
    ],
  },
];
