import * as FiIcons from "react-icons/fi";

export const ResponsiveMenuData = [
  {
    title: "Home",
    path: "/"
  },
  {
    title: "Publications",
    path: "/publications"
  },
  {
    title: "Patents",
    path: "/patents"
  },
  {
    title: "Awards",
    path: "/awards"
  },
  {
    title: "Memberships",
    path: "/memberships"
  },
  {
    title: "Contact",
    path: "/contact",
  },
];
